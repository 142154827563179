@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$link-color: $secondary;
$link-hover-color: darken($link-color, 15%);
$nav-pills-link-active-bg: $secondary;
@import "~bootstrap/scss/_mixins";


@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/print";

.bg-navblue {
  background: #28324e;
}
.scroll-content {
  height: 250px;
  padding: 7px;
  overflow: auto;
  border: 1px solid #ccc;
}
.scroll-content input {
  margin-right: 6px;
}
.role-name {
  color: #f51111;
  text-decoration: underline;
}
.salestracker-form label {
  font-size: 15px;
}
.salestracker-form input select {
  padding: 3px 9px;
}
.salestracker-table table tr {
  font-size: 13px;
}
.dt-button-collection .dropdown-item.active {
  background-color: #6c757d;
}
.dt-buttons .dropdown-menu {
  left: 28.3438px;
  padding: 1px;
}

